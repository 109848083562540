import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 9; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/21-sep-2/pic${i}.JPG`;
    imgArray.push(imgUrl);
  }

  return imgArray;
})();

export default function TheEchoOfNewIndia() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">
          CLASS VII PRESENTATION-ANUGOONJ...THE ECHO OF NEW INDIA
        </h1>

        <p>
          A thought provoking presentation was staged on 2nd September 2023 in
          Chinmaya Vidyalaya, New Delhi, by the students of class VII. The event
          beautifully depicted our motherland's history, from its golden era to
          foreign invasions, colonial rule, and the inspiring journey to modern
          digital India through poetry, skits, songs, mimes, and dance. It
          rekindled love for the country, highlighting India's global
          contributions from concept of zero to Chandrayaan. Principal Ms.
          Archana Soni praised it's insight into contemporary issues and
          stressed parental role modeling in teaching children their
          responsibilities. The event instilled strong national pride and
          optimism for our progressive future.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
